import React, { Component, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import App from './app'

const Container = () => {
    const [isMounted, setIsMounted] = useState(false)
    const [loadingPercentage, setLoadingPercentage] = useState(0)

    const rotateObj = useRef()

    var interval = ''

    const onClickMoveDownBtn = () => {
        if (rotateObj && rotateObj.current) {
            rotateObj.current.camera.position.y += 0.5
        }
    }

    const onClickMoveUpBtn = () => {
        if (rotateObj && rotateObj.current) {
            rotateObj.current.camera.position.y -= 0.5
        }
    }

    const onClickMoveLeftBtn = () => {
        if (rotateObj && rotateObj.current) {
            rotateObj.current.camera.position.x += 0.5
        }
    }

    const onClickMoveRightBtn = () => {
        if (rotateObj && rotateObj.current) {
            rotateObj.current.camera.position.x -= 0.5
        }
    }

    const onClickSpinWheelBtn = () => {
        if (interval != '') {
            clearInterval(interval)
            interval = ''
        } else {
            if (rotateObj.current) {
                interval = setInterval(() => {
                    if (rotateObj.current) {
                        rotateObj.current.controls.autoRotate = true
                        rotateObj.current.controls.autoRotateSpeed = 30
                        rotateObj.current.controls.update()
                    }
                }, 100)
            }
        }
    }

    return (
        <>
            <button onClick={() => setIsMounted(!isMounted)}>
                {isMounted ? 'Unmount' : 'Mount'}
            </button>

            <button
                className={isMounted ? '' : 'disabled-btn'}
                onClick={() => {
                    onClickMoveDownBtn()
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-down"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                    />
                </svg>
            </button>

            <button
                className={isMounted ? '' : 'disabled-btn'}
                onClick={() => {
                    onClickMoveUpBtn()
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                </svg>
            </button>

            <button
                className={isMounted ? '' : 'disabled-btn'}
                onClick={() => {
                    onClickMoveLeftBtn()
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                </svg>
            </button>
            <button
                className={isMounted ? '' : 'disabled-btn'}
                onClick={() => {
                    onClickMoveRightBtn()
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                </svg>
            </button>
            <button
                className={isMounted ? '' : 'disabled-btn'}
                onClick={() => {
                    onClickSpinWheelBtn()
                }}
            >
                SpinWheel
            </button>
            <App
                isMounted={isMounted}
                ref={rotateObj}
                onProgress={(loadingPercentage) =>
                    setLoadingPercentage(loadingPercentage)
                }
            />
            {isMounted && loadingPercentage === 100 && (
                <div>Scroll to zoom, drag to rotate</div>
            )}
            {isMounted && loadingPercentage !== 100 && (
                <div>Loading Model: {loadingPercentage}%</div>
            )}
        </>
    )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<Container />, rootElement)
