import React, { Component } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import './style.css'
const style = {
    height: 500, // we can control scene size by setting container dimensions
}

var h = 1.3333333432674408;
var pyramidGeom = new THREE.ConeBufferGeometry(Math.sqrt(2/3), h, 3);
pyramidGeom.translate(0, h * 0.5, 0);

var pyramidMat = new THREE.MeshBasicMaterial({color: "red"});
var pyramid = new THREE.Mesh(pyramidGeom, pyramidMat);



var raycaster = new THREE.Raycaster();
var mouse = new THREE.Vector2();
var plane = new THREE.Plane();
var pNormal = new THREE.Vector3(0, 1, 0); // plane's normal
var planeIntersect = new THREE.Vector3(); // point of intersection with the plane
var pIntersect = new THREE.Vector3(); // point of intersection with an object (plane's point)
var shift = new THREE.Vector3(); // distance between position of an object and points of intersection with the object
var isDragging = false;
var dragObject;
class App extends Component {
   
    componentDidMount() {
        this.sceneSetup()
        this.addLights()
        
//        this.startAnimationLoop()
        window.addEventListener('resize', this.handleWindowResize)
        var video = document.querySelector('#videoElement')

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                      facingMode: { exact: "environment" }
                    }
                  })
                .then(function (stream) {
                    video.srcObject = stream
                })
                .catch(function (err0r) {
                    console.log('Something went wrong!', err0r)
                })
        }

      //  this.loadTheModel().then((model)=>{



            
           
            // events
    
            
                document.addEventListener("pointermove", event => {
    
                    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
                    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
                raycaster.setFromCamera(mouse, this.camera);
                    
                if (isDragging) {
                    raycaster.ray.intersectPlane(plane, planeIntersect);
                    dragObject.position.addVectors(planeIntersect, shift);
                }
                });
        
                document.addEventListener("pointerdown", () => {
                    var intersects = raycaster.intersectObjects([pyramid]);
                if (intersects.length > 0) {
                    this.controls.enabled = false;
                    pIntersect.copy(intersects[0].point);
                    plane.setFromNormalAndCoplanarPoint(pNormal, pIntersect);
                    shift.subVectors(intersects[0].object.position, intersects[0].point);
                    isDragging = true;
                    dragObject = intersects[0].object;
                    
                }
                } );
        
                document.addEventListener("pointerup", () => {
                isDragging = false;
                dragObject = null;
                this.controls.enabled = true;
                } );
        //})
       
     
        
    }

    componentDidUpdate(state, newstate){
        console.log(state.isMounted, newstate)
        if(!state.isMounted){
            //this.scene.add(pyramid);
            this.loadTheModel()

        }else{
            //this.scene.remove(pyramid);
            if(this.model){
                this.scene.remove(this.model);
            }
        }
        
    }
    componentWillUnmount() {
   

        window.removeEventListener('resize', this.handleWindowResize)
        window.cancelAnimationFrame(this.requestID)
        this.controls.dispose()
    }

    // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
    // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
    sceneSetup = () => {
        // get container dimensions and use them for scene sizing
        const width = this.mount.clientWidth
        const height = this.mount.clientHeight

        this.scene = new THREE.Scene()
       this.camera =  new THREE.PerspectiveCamera(60, width / height, 1, 1000);
        // this.camera = new THREE.PerspectiveCamera(
        //     75, // fov = field of view
        //     width / height, // aspect ratio
        //     0.1, // near plane
        //     1000 // far plane
        // )
        this.camera.position.set(3, 5, 8);
        this.camera.lookAt(this.scene.position);
       // this.camera.position.z = 5 // is used here to set some distance from a cube that is located at z = 0
        // OrbitControls allow a camera to orbit around the object
        // https://threejs.org/docs/#examples/controls/OrbitControls
        this.controls = new OrbitControls(this.camera, this.mount)
        this.renderer = new THREE.WebGLRenderer({ alpha: true , antialias: true})
        this.renderer.setSize(width, height)
        this.mount.appendChild(this.renderer.domElement) // mount using React ref


        this.renderer.setAnimationLoop(() => {
            this.renderer.render(this.scene, this.camera);
          })


    }

    // Code below is taken from Three.js OBJ Loader example
    // https://threejs.org/docs/#examples/en/loaders/OBJLoader
    loadTheModel = () => {
        // instantiate a loader
        const loader = new OBJLoader()
        return new Promise((resolve)=>{
            // load a resource
        loader.load(
            // resource URL relative to the /public/index.html of the app
            'wheelspin.obj',
            // called when resource is loaded
            (object) => {
                this.scene.add(object)

                // get the newly added object by name specified in the OBJ model (that is Elephant_4 in my case)
                // you can always set console.log(this.scene) and check its children to know the name of a model
                const el = this.scene.getObjectByName('Elephant_4')
                // change some custom props of the element: placement, color, rotation, anything that should be
                // done once the model was loaded and ready for display
                el.position.set(0, -150, 0)
                el.material.color.set(0x50c878)
                el.rotation.x = 23.5
                console.log('eeee',el)

                // make this element available inside of the whole component to do any animation later
                this.model = el
                resolve(object)
            },
            // called when loading is in progresses
            (xhr) => {
                const loadingPercentage = Math.ceil(
                    (xhr.loaded / xhr.total) * 100
                )
                console.log(loadingPercentage + '% loaded')

                // update parent react component to display loading percentage
                this.props.onProgress(loadingPercentage)
            },
            // called when loading has errors
            (error) => {
                console.log('An error happened:' + error)
            }
        )
        })
        
    }

    // adding some lights to the scene
    addLights = () => {
        const lights = []

        // set color and intensity of lights
        lights[0] = new THREE.PointLight(0xffffff, 1, 0)
        lights[1] = new THREE.PointLight(0xffffff, 1, 0)
        lights[2] = new THREE.PointLight(0xffffff, 1, 0)

        // place some lights around the scene for best looks and feel
        lights[0].position.set(0, 2000, 0)
        lights[1].position.set(1000, 2000, 1000)
        lights[2].position.set(-1000, -2000, -1000)

        this.scene.add(lights[0])
        this.scene.add(lights[1])
        this.scene.add(lights[2])
    }

    startAnimationLoop = () => {
        // slowly rotate an object
        if (this.model) this.model.rotation.z += 0.005

        this.renderer.render(this.scene, this.camera)

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop)
    }

    handleWindowResize = () => {
        const width = this.mount.clientWidth
        const height = this.mount.clientHeight

        this.renderer.setSize(width, height)
        this.camera.aspect = width / height

        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        this.camera.updateProjectionMatrix()
    }

    render() {
        return (
            <div id="container">
                <video className='video' autoplay="true" style={{width:'100%', height:'100%'}} id="videoElement"></video>

               <div className='d-model' style={{height:"100%", width:'100%', display:(this.props.isMounted?'block':'block')}} ref={(ref) => (this.mount = ref)} />
            </div>
        )
    }
}

export default App
